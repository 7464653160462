@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #1111;
}

.main_header {
    position: fixed;
    z-index: 9;
    background: #212121;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.20124);
    width: 100%;
    padding: 0 45px;
    height: 90px;
    top: 0;
    z-index: 999;
}

.logo {
    mix-blend-mode: lighten;
}

.main_header .header_right_buttons ul {
    float: right;
    display: flex;
    gap: 10px;
}

.header-join-btn {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 27px;
    letter-spacing: 0.444444px;
    width: 130px;
    height: 47px;
    background: linear-gradient(270deg, rgba(14, 110, 255, 0) 0%, #74A9F9 242.69%);
    border-radius: 14px;
    border: 1px solid #74A9F9;
}


.download-btn {
    background: linear-gradient(227.72deg, #0E6EFF 6.9%, #185ABD 108.64%);
    border-radius: 14px;
    width: 205px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #ffffff;
    border: 0;
}

.footer {
    background: #212121;
    padding: 60px 0 0;

}

.footer_para {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.footer-second {
    margin-top: 60px;
    padding: 15px 0;
    height: 50px;
    background: black;
    margin-right: 0px;
    margin-left: 0px;
}



.promotion-card {
    width: 256px;
    height: 457px;
}


.promotion-card-body {
    text-align: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.79) 100%);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    width: 100%;
    height: 115px;

}

.promotion-card-body p {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.444444px;
}

.promotiona-header,
.flipout-header {
    background-color: #212121;
    text-align: center;
    height: 99px;
    padding: 39px 0px 45px 0px;
    background: #212121;
    box-shadow: 0px -1px 23px rgba(0, 0, 0, 0.52);
}

.promotiona-header h1,
.flipout-header h1 {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    /* line-height: 36px; */
    margin: 0;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
}

.footer-select {
    background: black;
    font-weight: 700;
    border: 0;
    font-size: 16px;
    line-height: 150%;
    color: #F8F9FA;
}

.footer-second-para {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #A2A9B0;
}




.whyplay-card p:nth-child(1) {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
}

.whyplay-card p:nth-child(2) {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

}

.divider {
    height: 3px;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.85);
    margin-left: 50px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin-right: 50px;
    background-color: rgba(255, 255, 255, 0.11);
}

.stepper {
    text-align: center;
}

.stepper .stepper-head {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
    margin: 0;
    color: #FFFFFF;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -50%);
}

.stepper .stepper-title {
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.0924px;
    color: #FFFFFF;

}

.month {
    background: radial-gradient(38.71% 15.7% at 50.76% 15.03%, rgba(0, 73, 141, 0.3) 0%, rgba(0, 73, 141, 0) 100%)
}

.month-card {
    width: 404px;
    /* height: 278px; */
    background: linear-gradient(185.23deg, rgba(0, 0, 0, 0) 42.67%, rgba(0, 0, 0, 0.74) 63.68%);
}

.month-custom {
    background: #212121;
}

.month-card img {
    object-fit: cover;
}

.month-card-body h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.444444px;
    color: #FFFFFF;
}

.month-card-body p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.444444px;
    color: #FFFFFF;
}

.month-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.first-footer {
    text-align: center;
    padding: 5rem 0;
    color: #fff;
}

.first-footer h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
    color: #ffffff;
}

.first-footer p {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.444444px;
}

.flipout p {
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.05544px;
    color: #FFFFFF;

    text-shadow: 0px 0px 56px #000000;
}

.aboutggcare-steps p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
    color: #FFFFFF;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutggcare-body p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.444444px;
    color: #FFFFFF;
}

.thanksgg {
    background: url('../Images/aboutgg4.png') center no-repeat;
    background-size: cover;
    height: 332px;
    /* padding: 60px 0; */
}

.thanksgg-img {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    margin: auto;
}

.thanksgg p {
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.05544px;
    color: #FFFFFF;
    text-shadow: 0px 0px 56px #000000;
    padding: 20px 0;
}

.faq-body h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.05544px;
    color: #FFFFFF;
}

.faq-body p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.05544px;
    color: #FFFFFF;
}

.Terms {
    background: #212121;
    box-shadow: 0px -1px 23px rgba(0, 0, 0, 0.52);
}

.Terms h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.Terms ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

@media only screen and (min-width:768px) and (max-width: 1468px) {

    .stepper .stepper-head,
    .stepper .stepper-title {
        font-size: 20px;
        line-height: 15px;
    }
}